import { VFC } from "react";

import { Box } from "theme-ui";

import { Popout } from "src/ui/popout";

import { colors } from "../../../../design/colors";

export const popoverContent = {
  selectSource: {
    variant: "secondary",
    arrowPosition: "left",
    text: <div>A source is where your data is stored, usually a database, warehouse, or even a BI tool.</div>,
  },
  selectDestination: {
    variant: "secondary",
    arrowPosition: "left",
    text: (
      <div>
        A destination is where you want to send data to. Hightouch brings more than a hundred out-of-the-box destinations.
      </div>
    ),
  },
  connectSource: {
    variant: "primary",
    arrowPosition: "left",
    text: <div>Connect your source so Hightouch can pull data from it.</div>,
  },
  connectDestination: {
    variant: "primary",
    arrowPosition: "left",
    text: <div>Connect your destination so that Hightouch can load your source data to it.</div>,
  },
  setupModel: {
    variant: "primary",
    arrowPosition: "left",
    text: <div>A model in Hightouch gives you the chance to manipulate your data before it's sent to a destination.</div>,
  },
  createSync: {
    variant: "primary",
    arrowPosition: "center",
    text: (
      <div>
        Finally, configure your data sync and hit run. A sync lets you choose which fields you want to push from your model to
        your destination.
      </div>
    ),
  },
  runSync: {
    variant: "primary",
    arrowPosition: "center",
    text: <div>Hightouch is ready to send data to your destination! Go to your Sync and click Run.</div>,
  },
  modelDisabled: {
    variant: "secondary",
    arrowPosition: "left",
    text: <div>In order to start setting up your model you first need to have your source connected.</div>,
  },
  syncDisabled: {
    variant: "secondary",
    arrowPosition: "center",
    text: <div>In order to create a sync you need to configure your source, model and destination first.</div>,
  },
  createYourFirstSyncDisabled: {
    variant: "secondary",
    arrowPosition: "center",
    text: <div>Select a sync and destination to get started.</div>,
  },
};

type Props = {
  content: keyof typeof popoverContent;
  children?: JSX.Element;
  footer?: JSX.Element;
  isOpen?: boolean;
  asTooltip?: boolean;
  disabled: boolean;
};

export const Popover: VFC<Props> = ({ children, footer, disabled, isOpen, content, asTooltip }) => {
  const text = popoverContent[content].text;
  const variant = popoverContent[content].variant;
  const arrowPosition = popoverContent[content].arrowPosition;

  return (
    <Popout
      portal
      content={
        <Box>
          <Box
            sx={{
              borderStyle: "solid",
              borderColor: variant === "secondary" ? colors.base[8] : colors.primary,
              borderWidth: "0 4px 4px 0",
              display: "inline-block",
              padding: "3px",
              transform: " rotate(-135deg)",
              position: "relative",
              top: "-10px",
              bg: variant === "secondary" ? colors.base[8] : colors.primary,
              left: arrowPosition === "center" ? "175px" : "30px",
            }}
          />

          <Box sx={{ fontSize: "14px", lineHeight: "24px", display: "flex", alignContent: "flex-start", p: 4, pt: 0 }}>
            <Box sx={{ flexGrow: 1 }}>
              {text}
              {footer && <Box>{footer}</Box>}
            </Box>
          </Box>
        </Box>
      }
      contentSx={{
        background: variant === "secondary" ? colors.base[8] : colors.primary,
        color: colors.white,
        borderRadius: 6,
        width: 360,
        shadow: 2,
        display: disabled ? "none" : "inline",
      }}
      ignoreClose={!asTooltip}
      isOpen={isOpen}
      placement="bottom"
    >
      {({ open, close }) => (
        <Box
          sx={{ cursor: disabled ? "default" : "pointer", width: "100%" }}
          onMouseEnter={() => asTooltip && open()}
          onMouseLeave={() => asTooltip && close()}
        >
          {children}
        </Box>
      )}
    </Popout>
  );
};
