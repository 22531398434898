import { VFC, useMemo, useEffect } from "react";

import { capitalize } from "lodash";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications";
import { Box } from "theme-ui";

import { TunnelSelect } from "src/components/tunnels/tunnel-select";
import { Form as FormkitForm } from "src/formkit/components/form";
import { FormkitProvider } from "src/formkit/components/formkit-context";
import { processFormNode } from "src/formkit/formkit";
import { SourceDefinition, useFormkitSourceDefinitionQuery, useFormkitSourceValidationQuery } from "src/graphql";
import { Row } from "src/ui/box/row";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { RadioGroup } from "src/ui/radio";

import { colors } from "../../../../../design";
import { IPWhitelistMessage } from "../ip-whitelist-message";
import clickhouse from "./clickhouse";
import { DatabricksForm } from "./databricks";
import elasticsearch from "./elasticsearch";
import firebolt from "./firebolt";
import foundry from "./foundry";
import { LookerForm } from "./looker";
import metabase from "./metabase";
import mixpanel from "./mixpanel";
import { MssqlForm } from "./mssql";
import { CredentialSection } from "./providers";
import { RocksetForm } from "./rockset";
import sftp from "./sftp";
import trino from "./trino";

const SOURCES: {
  [type: string]: {
    ConfigurationForm: VFC<any>;
    isConfigurationComplete?: (configuration: Record<string, unknown>, tunnel?: any, credentialId?: any) => boolean;
  };
} = {
  trino,
  mixpanel,
  foundry,
  clickhouse,
  firebolt,
  metabase,
  sftp,
  elasticsearch,

  databricks: { ConfigurationForm: DatabricksForm },
  looker: { ConfigurationForm: LookerForm },
  mssql: { ConfigurationForm: MssqlForm },
  rockset: { ConfigurationForm: RocksetForm },
};

export const isConfigComplete = (type: string | undefined, config: any, tunnel?: any, credentialId?: any) => {
  const source = type ? SOURCES[type] : undefined;

  if (source?.isConfigurationComplete) {
    return source.isConfigurationComplete(config, tunnel, credentialId);
  }

  // For new sources, try to implement `isConfigurationComplete` instead of adding to this `switch` statement.
  switch (type) {
    case "bigquery":
      return config?.project && config?.location;
    case "looker":
      return config?.url && config?.clientId && config?.connectionName;
    case "mssql":
      if (config?.connectionString) {
        return true;
      }

      if (tunnel) {
        return config?.user && config?.database;
      } else {
        return config?.user && config?.server && config?.database && config?.port;
      }
    case "databricks":
      return config?.host && config?.httpPath && config?.defaultSchema;
    case "rockset":
      return config?.region && config?.apiKey;
    case "mixpanel":
      return config?.username && config?.password;
    case "firebolt":
      return config?.username && config?.password && config?.database && config?.engine;
    default:
      return true;
  }
};

export type FormProps = {
  sourceId: string | undefined;
  definition: SourceDefinition;
  tunnel?: any;
  source?: any;
  setTunnel?: any;
  config?: any;
  setConfig?: any;
  credentialId?: any;
  setCredentialId?: any;
  name?: string;
  setName?: any;
  hideSecret?: boolean;
  error?: any;
  onSubmit?: (config: any) => Promise<void>;
  /**Wheter to disable authentication method radio selection. */
  disableAuthMethod?: boolean;
  /**Function called whenever OAuth 'connect' button is clicked. */
  onConnectClick?(defintion: SourceDefinition): void;
  isSetup: boolean;
};

export const SourceForm: VFC<Readonly<FormProps>> = ({ definition, setConfig, config, sourceId, ...props }) => {
  //OLD (Delete after all sources are converted to formkit)
  const Form = SOURCES[definition.type]?.ConfigurationForm;

  //NEW
  const client = useQueryClient();
  const { addToast } = useToasts();
  const { data } = useFormkitSourceDefinitionQuery({ type: definition?.type }, { enabled: true });

  const sourceDefinitions = data?.formkitSourceDefinition;
  const context = {
    sourceDefinition: definition,
    sourceId,
    isSetup: props.isSetup,
    tunnel: props.tunnel,
    credentialId: props.credentialId,
  };

  const validate = async (config, context) => {
    const response = await client.fetchQuery({
      queryFn: useFormkitSourceValidationQuery.fetcher({
        type: context.sourceDefinition?.type,
        config,
        context,
      }),
      queryKey: useFormkitSourceValidationQuery.getKey({ ...config, ...context }),
    });

    return response.formkitSourceValidation;
  };

  const methods = useForm({ defaultValues: config });

  useEffect(() => {
    //Autoset authentication method if only one
    const firstSourceDefinition = sourceDefinitions?.[0];

    if (sourceDefinitions?.length === 1 && firstSourceDefinition) {
      setConfig((prev) => ({ ...prev, methodKey: firstSourceDefinition.key }));
      methods.setValue("methodKey", firstSourceDefinition.key);
    }
  }, [definition.type, sourceDefinitions?.length]);

  useEffect(() => {
    //Watches fields and sets config
    const fieldWatcher = methods.watch((value) => {
      setConfig(value);
    });
    return () => fieldWatcher.unsubscribe();
  }, [methods.watch]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (sourceDefinitions?.length === 0) {
      if (typeof props.onSubmit === "function") {
        return await props.onSubmit(data);
      }

      return;
    }

    const errors = await validate(config, context);
    if (typeof errors === "object" && Object.keys(errors).length) {
      methods.clearErrors();
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });
      addToast("There was an error within the source configuration.", {
        appearance: "error",
      });
    } else {
      if (typeof props.onSubmit === "function") {
        await props.onSubmit(data);
      }
    }
  };

  return (
    <FormkitProvider {...context} validate={validate}>
      <FormProvider {...methods}>
        {definition.supportsIpFiltering && (
          <Row>
            <IPWhitelistMessage />
          </Row>
        )}
        {Array.isArray(sourceDefinitions) && sourceDefinitions?.length > 0 ? (
          <>
            {sourceDefinitions.length > 1 && (
              <Field label="Authentication Method">
                <Controller
                  control={methods.control}
                  name={`methodKey`}
                  render={({ field }) => (
                    <RadioGroup
                      {...field}
                      disabled={props.disableAuthMethod}
                      options={sourceDefinitions.map((o) => ({ label: o.label || capitalize(o.key), value: o.key }))}
                      value={config?.methodKey}
                    />
                  )}
                  rules={{ required: true }}
                />
              </Field>
            )}
            <SuccessMessage display={Boolean(props.disableAuthMethod && sourceId)} sourceName={definition.name} />
            <SelectedMethodForm
              config={config}
              credentialId={props.credentialId}
              definition={definition}
              error={props.error}
              id={sourceId}
              setConfig={props.setCredentialId}
              setCredentialId={props.setCredentialId}
              setTunnel={props.setTunnel}
              source={props.source}
              sourceDefinitions={sourceDefinitions}
              tunnel={props.tunnel}
              onConnectClick={props.onConnectClick}
            />
            <FieldError error={props.error} />
          </>
        ) : (
          <>
            {definition.supportsTunneling && <TunnelSelect optional value={props.tunnel} onChange={props.setTunnel} />}
            {Form && <Form config={config} setConfig={setConfig} {...props} />}
          </>
        )}
        <form hidden id="source-form" onSubmit={handleSubmit} />
      </FormProvider>
    </FormkitProvider>
  );
};

export type MethodProps = {
  definition: SourceDefinition;
  config: Record<string, any>;
  setConfig?: any;
  sourceDefinitions: Record<string, any>;
  id?: string;
  onConnectClick?(defintion: SourceDefinition): void;
  credentialId?: any;
  setCredentialId?: any;
  tunnel?: any;
  setTunnel?: any;
  error?: any;
  source?: any;
};

const SelectedMethodForm: VFC<Readonly<MethodProps>> = ({
  definition,
  config,
  sourceDefinitions,
  id,
  onConnectClick,
  ...props
}) => {
  const matchingSource = sourceDefinitions?.find((o) => o.key === config?.methodKey);

  const Form = useMemo(
    () =>
      matchingSource?.form && (
        <FormkitForm compact={true} disableBorder={true}>
          {processFormNode(matchingSource.form)}
        </FormkitForm>
      ),
    [matchingSource?.key],
  );

  if (matchingSource?.method === "form") {
    return (
      <>
        {matchingSource?.tunneling && <TunnelSelect optional value={props.tunnel} onChange={props.setTunnel} />}
        <CredentialSection definition={definition} matchingSource={matchingSource} {...props} />
        {Form}
      </>
    );
  } else if (matchingSource?.method === "oauth") {
    if (!id) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "18px",
            border: `1px solid ${colors.base[3]}`,
            padding: 4,
            borderRadius: 2,
          }}
        >
          <Box>Connect with OAuth</Box>
          <Link to={`${import.meta.env.VITE_API_BASE_URL}${matchingSource.url}/${definition.type}/${config?.methodKey}`}>
            <Button
              variant="secondary"
              onClick={() => {
                onConnectClick && onConnectClick(definition);
              }}
            >
              Connect
            </Button>
          </Link>
        </Box>
      );
    } else if (matchingSource?.form) {
      return Form;
    } else {
      return <SuccessMessage display={true} sourceName={definition.name} />;
    }
  } else {
    return null;
  }
};

const SuccessMessage: VFC<Readonly<{ display: boolean; sourceName: string }>> = ({ display, sourceName }) => {
  if (display) {
    return <Message>Authorization successful! Your {sourceName} account was successfully connected.</Message>;
  } else {
    return null;
  }
};
